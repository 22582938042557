<template>
  <div v-if="result">
    <div class="top_image">
      <img :src="require(`@/assets/img/${result.image}`)" />
    </div>
    <div class="statement">
      <p v-if="scoreText">{{ scoreText }}</p>
      <p>{{ result.text }}</p>
    </div>
    <ResultBtns :status="status" :share-text="shareText" @retry="onRetry" />
  </div>
</template>

<script>
import mixins from "@/components/mixins/utils";
import ResultBtns from "@/components/ResultBtns";

export default {
  components: { ResultBtns },
  mixins: [mixins],
  props: {
    items: {
      type: [Array, null],
      default: null,
    },
    status: {
      type: [String, null],
      default: null,
    },
    score: {
      type: [Number, null],
      default: null,
    },
  },
  created() {
    if (this.isAllCorrect) {
      this.$store.commit("isAllCorrect", true);
    }
  },
  computed: {
    result() {
      const { status, score } = this;
      const result = this.getResult(status);
      // result.data があり score がマッチしたら、そのデータも返す
      const resultData =
        (result.data &&
          result.data.find((e) => {
            return String(score).match(`[${e.score}]`);
          })) ||
        {};
      return {
        ...result,
        ...resultData,
      };
    },
    scoreText() {
      const { score } = this;
      return (score && `アベンジャーズオタク度【${score * 10}%】\n\n`) || null;
    },
    shareText() {
      const { $getShareText, result, score } = this;
      return (
        result && result.shareText && $getShareText(result.shareText, score)
      );
    },
    isAllCorrect() {
      return this.status === "all_correct";
    },
    isIncorrect() {
      return this.status === "incorrect";
    },
  },
  methods: {
    onRetry() {
      this.$emit("retry", true);
    },
    getResult(resultType) {
      return this.items.find((e) => e && e.meta === resultType);
    },
  },
};
</script>
