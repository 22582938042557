import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import config from "@/assets/data/config";

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state() {
    return {
      isAllCorrect: false,
      isSubmitted: false,
    };
  },
  getters: {
    isShow(state) {
      const { SHOW_ON_CORRECT } = config;
      return !SHOW_ON_CORRECT || (SHOW_ON_CORRECT && state.isAllCorrect);
    },
    isAllCorrect(state) {
      return state.isAllCorrect;
    },
    isSubmitted(state) {
      return state.isSubmitted;
    },
    canSubmit(state) {
      const { ALLOW_DUPLICATE } = config;
      return ALLOW_DUPLICATE || (!ALLOW_DUPLICATE && !state.isSubmitted);
    },
  },
  mutations: {
    isAllCorrect(state, payload) {
      state.isAllCorrect = payload || false;
    },
    isSubmitted(state, payload) {
      state.isSubmitted = payload || false;
    },
  },
  actions: {},
  modules: {},
  plugins: [vuexLocal.plugin],
});
