<template>
  <div v-if="item">
    <div v-if="item.image" class="top_image">
      <img :src="require(`@/assets/img/${item.image}`)" />
    </div>
    <div class="statement">
      <p>【第 {{ currentQuiz + 1 }} 問】</p>
      <p>{{ question }}</p>
    </div>
    <div v-for="(answer, index) in answers" :key="index">
      <div class="button_area">
        <button @click="onSelected(answer, index)">
          {{ answer }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "@/components/mixins/utils";
import config from "@/assets/data/config";
import q from "@/assets/data/questionsOutput";

const getQuestionsRandom = (items) => {
  return items.sort(() => Math.random() - 0.5);
};
const getQuestionsSort = (items) => {
  return (
    items
      // level 指定がない場合は level0 とします
      .map((e) => {
        if (e.level === "") {
          e.level = 0;
        }
        return e;
      })
      // level の昇順にソート
      .sort((a, b) => {
        if (a.level !== b.level) {
          return Number(a.level) - Number(b.level);
        }
      })
  );
};

export default {
  mixins: [mixins],
  props: {
    questions: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      q,
      correctCount: 0,
      currentQuiz: 0,
      currentQuizText: null,
      isAllCorrectMode: config.END_ON_INCORRECT,
      isRandomMode: config.IS_RANDOM,
    };
  },
  computed: {
    items() {
      const { questions, isRandomMode, q } = this;
      const items = getQuestionsSort(
        isRandomMode ? getQuestionsRandom(questions) : questions
      );
      const ret = [];
      q.forEach((e) => {
        const start = ret.length;
        items.forEach((item) => {
          const len = ret.length - start;
          // level毎に出題数まで ret 配列にデータを格納
          e.level === Number(item.level) && e.output > len && ret.push(item);
        });
      });
      return ret;
    },
    item() {
      return this.items[this.currentQuiz] || null;
    },
    answers() {
      const { answers } = this.item;
      return answers || null;
    },
    question() {
      const { question } = this.item;
      return question || null;
    },
    isFinished() {
      return this.items && this.items.length === this.currentQuiz;
    },
    isAllCorrect() {
      return this.items && this.items.length === this.correctCount;
    },
  },
  methods: {
    onSelected(data, index) {
      const isCorrect = index + 1 === Number(this.item.correct);

      this.currentQuiz++;
      isCorrect && this.correctCount++;

      if (this.isFinished) {
        // 全問正解！
        if (this.isAllCorrect) {
          this.$emit("result", "all_correct");
          this.$setPageViewGtagEvent(config.ALL_CORRECT_PAGE_VIEWED);
        } else {
          // 正解数を表示するパターン
          this.$emit("result", `score`);
          this.$emit("score", this.correctCount);
        }
        this.$setPageViewGtagEvent(config.RESULT_PAGE_VIEWED);
        this.reset();
      } else {
        // 全問正解モードで不正解した場合
        if (!isCorrect && this.isAllCorrectMode) {
          this.reset();
          this.$emit("result", "incorrect");
          this.$setPageViewGtagEvent(config.RESULT_PAGE_VIEWED);
        }
      }
    },
    reset() {
      this.currentQuiz = 0;
      this.correctCount = 0;
    },
  },
};
</script>
