<template>
  <div>
    <div v-for="(btn, index) in btns" :key="index">
      <div v-if="showBtn(btn)" class="button_area">
        <component
          :is="btn.url ? 'a' : 'button'"
          :href="btn.url"
          target="_blank"
          @click="onClick(btn)"
        >
          {{ btn.label }}
        </component>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "@/components/mixins/utils";
import resultBtns from "@/assets/data/resultBtns";

export default {
  mixins: [mixins],
  props: {
    click: {
      type: Function,
      require: true,
    },
    status: {
      type: String,
      require: true,
    },
    shareText: {
      type: String,
      require: true,
    },
  },
  computed: {
    isShow() {
      return this.$store.getters["isShow"];
    },
    canSubmit() {
      return this.$store.getters["canSubmit"];
    },
    btns() {
      const { status, shareText } = this;
      return (
        status &&
        resultBtns.map((btn) => {
          if (btn.meta === "share") {
            btn.url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
              shareText
            )}`;
          }
          return !btn.hide.includes(status) && btn;
        })
      );
    },
  },
  methods: {
    onClick(data) {
      const { meta, gtag } = data;
      gtag && this.$setSelectedGtagEvent(gtag);
      // retry
      meta === "retry" && this.$emit("retry", data);
      // form
      meta === "form" && this.$router.push("/form/");
    },
    showBtn(data) {
      const { meta } = data;
      const status = ["form"];
      return (
        (this.isShow && status.includes(meta) && this.canSubmit) ||
        (!status.includes(meta) && data)
      );
    },
  },
};
</script>
