<template>
  <div class="container">
    <div class="main">
      <Result
        v-if="isResult"
        :items="results"
        :status="status"
        :score="score"
        @retry="onRetry"
      />
      <template v-else>
        <Questions
          v-if="questions"
          :questions="questions"
          @result="onResult"
          @score="getScore"
        />
      </template>
    </div>
  </div>
</template>

<script>
import questionsCsv from "@/assets/data/questions.csv";
import results from "@/assets/data/results";

import Questions from "@/components/home/Questions";
import Result from "@/components/home/Result";

export default {
  name: "Home",
  components: { Questions, Result },
  data() {
    const { data } = this.$papa.parse(this.$papa.unparse(questionsCsv), {
      header: true,
      skipEmptyLines: true,
    });
    const questions = data.map((line) => {
      const { answer1, answer2, answer3, answer4, answer5 } = line;
      const answers = [];
      answer1 && answers.push(answer1);
      answer2 && answers.push(answer2);
      answer3 && answers.push(answer3);
      answer4 && answers.push(answer4);
      answer5 && answers.push(answer5);
      line.answers = answers;
      return line;
    });
    return {
      results,
      questions,
      isResult: false,
      status: null,
      score: null,
    };
  },
  methods: {
    getScore(score) {
      if (Number.isInteger(score)) {
        this.score = score;
      }
    },
    onResult(status) {
      this.isResult = true;
      this.status = status;
    },
    onRetry() {
      this.isResult = false;
      this.status = null;
      this.score = null;
    },
  },
};
</script>
