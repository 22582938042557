import Vue from "vue";
// see https://matteo-gabriele.gitbook.io/vue-gtag/v/master/
import VueGtag from "vue-gtag";
// see https://www.npmjs.com/package/vue-papa-parse
import VuePapaParse from "vue-papa-parse";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import css from "./assets/css/main.scss";

Vue.config.productionTip = false;

Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_MEASURE_ID },
  },
  router
);
Vue.use(VuePapaParse);

new Vue({
  router,
  store,
  css,
  render: (h) => h(App),
}).$mount("#app");
