export default {
  // ランダムで出題
  IS_RANDOM: false,
  // 不正解で終了
  END_ON_INCORRECT: false,
  // 全問正解で表示（フォームなど）
  SHOW_ON_CORRECT: false,
  // 重複送信を許可
  ALLOW_DUPLICATE: false,
  // GoogleFormのURL（ iframe の src ）
  GOOGLE_FORM_URL:
    process.env.VUE_APP_GOOGLE_FORM_URL ||
    "https://docs.google.com/forms/d/e/1FAIpQLSdxne0fVeHoAsBjqRqA1F7Z3o1ei1S5LWOCIzLfw2mHhrEosw/viewform?embedded=true",
  // gtag event name
  RESULT_PAGE_VIEWED: "result_page_viewed",
  ALL_CORRECT_PAGE_VIEWED: "all_correct_page_viewed",
};
