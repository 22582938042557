/**
 * hide に設定するステータス: all_correct, incorrect, score, thanks
 * hide: 指定のステータスの場合は非表示
 */
const TW_SCREEN_NAME = "SH96810505";
export default [
  {
    meta: "form",
    gtag: "form_button_clicked",
    label: "キャンペーンに応募する",
    hide: ["all_correct", "incorrect", "score", "thanks"],
  },
  {
    meta: "share",
    gtag: "share_button_clicked",
    label: "結果をシェアする",
    url: ``, // Result.vue で動的に生成します
    hide: ["incorrect"],
  },
  {
    meta: "follow",
    gtag: "follow_button_clicked",
    label: "アカウントをフォローする",
    url: `https://twitter.com/intent/follow?screen_name=${TW_SCREEN_NAME}`,
    hide: ["all_correct", "incorrect", "score", "thanks"],
  },
  {
    meta: "url",
    gtag: "url_button_clicked",
    label: "キャンペーンサイトを見る",
    url: "https://www.flag-pictures.co.jp",
    hide: ["all_correct", "incorrect", "score", "thanks"],
  },
  {
    meta: "retry",
    gtag: "retry_button_clicked",
    label: "もう一度診断する",
    hide: [],
  },
];
