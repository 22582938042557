/**
 * 送信完了画面
 */
export default {
  meta: "thanks",
  image: "all_correct_complete.png",
  text: "応募ありがとうございました！",
  shareText:
    "#チェリまほ超マニアッククイズ企画 に挑戦した結果…全問正解でした🧙‍♀️💫🍭🍬⚡️\n\n全問正解すると、豪華な賞品が当たるキャンペーン実施中です。是非チャレンジしてみてください🌱\n${url}",
};
