<template>
  <div class="container">
    <iframe
      v-if="isShow && canSubmit && formUrl"
      :src="formUrl"
      frameborder="0"
      marginheight="0"
      marginwidth="0"
      width="640"
      @load="onLoad"
      >読み込んでいます…</iframe
    >
    <div v-else class="main">フォームの読み込みに失敗しました</div>
  </div>
</template>

<script>
import store from "@/store";
import urljoin from "url-join";
import config from "@/assets/data/config";

export default {
  data() {
    return {
      loadedCount: 0,
    };
  },
  computed: {
    formUrl() {
      const { GOOGLE_FORM_URL: url } = config;
      return url && urljoin(url.replace(/\?.*$/, ""), "?embedded=true");
    },
    isShow() {
      return this.$store.getters["isShow"];
    },
    canSubmit() {
      return this.$store.getters["canSubmit"];
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!store.getters["canSubmit"]) {
      next("/form/thanks");
    }
    next();
  },
  methods: {
    onLoad() {
      this.loadedCount++;
      // 1回以上読み込みで送信完了
      if (this.loadedCount > 1) {
        this.$store.commit("isSubmitted", true);
        this.$router.push("/form/thanks");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  text-align: center;
  iframe {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    padding: 25px;
  }
}
</style>
