export default {
  methods: {
    $setPageViewGtagEvent(eventName) {
      this.$gtag.event(eventName, {
        event_category: "engagement",
        event_label: "page_view",
      });
    },
    $setSelectedGtagEvent(eventName) {
      this.$gtag.event(eventName, {
        event_category: "engagement",
        event_label: "selected",
      });
    },
    $getShareText(str, score = 0) {
      const { VUE_APP_URL: url } = process.env;
      return str.replace("${url}", url).replace("${score}", score * 10);
    },
  },
};
