/**
 * data がある場合は、score に半角英数字を設定ください。
 * （Result.vue 内にて match(/[score]/) で判定してます。）
 */
export default [
  {
    meta: "all_correct",
    image: "Result_Best_0608_01_2.png",
    text: "アベンジャーズオタク度【100%】\n\nこれまでのMCU作品を完璧に抑えて、\n次の新ヒーローに出会う準備も万端！\nアベンジャーズオタクの高校生がヒーローになる物語『ミズ・マーベル』もお見逃しなく！ディズニープラスで独占配信中！",
    shareText:
      "#アベンジャーズオタク度診断 の結果は…\n\n【アベンジャーズオタク度100％】\n\n#マーベルスタジオ ドラマ最新作『ミズ・マーベル』はアベンジャーズオタクが主人公⁉️ @MarvelStudios_J をフォローしてあなたのオタク度もチェックしよう！💫\n${url}",
  },
  {
    meta: "incorrect",
    image: "result_incorrect.png",
    text: "ざんねん！不正解！",
    shareText:
      "私は途中で間違えてしまいました！\nみんなもやってみよう！\n${url}",
  },
  {
    meta: "score",
    shareText:
      "#アベンジャーズオタク度診断 の結果は…\n\n【アベンジャーズオタク度${score}％】\n\n#マーベルスタジオ ドラマ最新作『ミズ・マーベル』はアベンジャーズオタクが主人公⁉️ @MarvelStudios_J をフォローしてあなたのオタク度もチェックしよう！💫\n${url}",
    data: [
      {
        score: "0",
        image: "Result_Beginner_0608_01_2.png",
        text: "アベンジャーズオタク度【0%】\n\nもしかしてMCUに出会ったばかり？\nこれから楽しめるヒーローの物語が沢山！おすすめは、新たなヒーローを描く最新作『ミズ・マーベル』！他のマーベル作品とあわせてチェックしよう！ディズニープラスで独占配信中！",
      },
      {
        score: "1",
        image: "Result_Beginner_0608_01_2.png",
        text: "もしかしてMCUに出会ったばかり？\nこれから楽しめるヒーローの物語が沢山！おすすめは、新たなヒーローを描く最新作『ミズ・マーベル』！他のマーベル作品とあわせてチェックしよう！ディズニープラスで独占配信中！",
      },
      {
        score: "2",
        image: "Result_Beginner_0608_01_2.png",
        text: "もしかしてMCUに出会ったばかり？\nこれから楽しめるヒーローの物語が沢山！おすすめは、新たなヒーローを描く最新作『ミズ・マーベル』！他のマーベル作品とあわせてチェックしよう！ディズニープラスで独占配信中！",
      },
      {
        score: "3",
        image: "Result_Beginner_0608_01_2.png",
        text: "もしかしてMCUに出会ったばかり？\nこれから楽しめるヒーローの物語が沢山！おすすめは、新たなヒーローを描く最新作『ミズ・マーベル』！他のマーベル作品とあわせてチェックしよう！ディズニープラスで独占配信中！",
      },
      {
        score: "4",
        image: "Result_Mid_0608_01_2.png",
        text: "好きなヒーローと作品はとことん好き！\nでも見逃している作品もあるかも？気になった作品はディズニープラスでチェックしよう！新たな高校生ヒーローの物語『ミズ・マーベル』もお見逃しなく！ディズニープラスで独占配信中！",
      },
      {
        score: "5",
        image: "Result_Mid_0608_01_2.png",
        text: "好きなヒーローと作品はとことん好き！\nでも見逃している作品もあるかも？気になった作品はディズニープラスでチェックしよう！新たな高校生ヒーローの物語『ミズ・マーベル』もお見逃しなく！ディズニープラスで独占配信中！",
      },
      {
        score: "6",
        image: "Result_MidHigh_0608_01_2.png",
        text: "MCUが大好きなあなたは作品も一通りチェック済み！でも、ところどころ曖昧な部分も？気になった点をディズニープラスでもう一度観てみよう！最新作の『ミズ・マーベル』もお見逃しなく！ディズニープラスで独占配信中！",
      },
      {
        score: "7",
        image: "Result_MidHigh_0608_01_2.png",
        text: "MCUが大好きなあなたは作品も一通りチェック済み！でも、ところどころ曖昧な部分も？気になった点をディズニープラスでもう一度観てみよう！最新作の『ミズ・マーベル』もお見逃しなく！ディズニープラスで独占配信中！",
      },
      {
        score: "8",
        image: "Result_Advanced_0608_01_2.png",
        text: "MCU上級者のあなたは全作品の知識も＜ほぼ＞完璧！ちょっとだけ足りなかったところはディズニープラスで復習しつつ、新ヒーロー『ミズ・マーベル』の物語をチェックしよう！ディズニープラスで独占配信中！",
      },
      {
        score: "9",
        image: "Result_Advanced_0608_01_2.png",
        text: "MCU上級者のあなたは全作品の知識も＜ほぼ＞完璧！ちょっとだけ足りなかったところはディズニープラスで復習しつつ、新ヒーロー『ミズ・マーベル』の物語をチェックしよう！ディズニープラスで独占配信中！",
      },
    ],
  },
];
