<template>
  <div class="container">
    <div class="main">
      <div class="top_image">
        <img :src="require(`@/assets/img/${thanks.image}`)" />
      </div>
      <div class="statement">
        <p>{{ thanks.text }}</p>
      </div>
      <ResultBtns :status="status" :share-text="shareText" @retry="onRetry" />
    </div>
  </div>
</template>

<script>
import mixins from "@/components/mixins/utils";
import thanks from "@/assets/data/thanks";
import ResultBtns from "@/components/ResultBtns";

export default {
  components: { ResultBtns },
  mixins: [mixins],
  data() {
    return {
      thanks,
    };
  },
  computed: {
    status() {
      return this.thanks.meta;
    },
    shareText() {
      return this.$getShareText(this.thanks.shareText);
    },
  },
  methods: {
    onRetry() {
      this.$router.push("/");
    },
  },
};
</script>
